<template>
  <div class="sub_item_wrap">
    <div
      class="flex justify-space-between align-center"
      @click="isShow = !isShow"
    >
      <div class="sub_item_title">
        {{ detailShowTitle }}
      </div>
      <div
        class="button-group"
      >
        <div
          class="del-button"
        >
          <v-icon v-if="isShow">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else>
            mdi-chevron-down
          </v-icon>
        </div>
      </div>
    </div>
    <div
      v-if="isShow"
      style="
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      border-bottom: 2px solid #aaaaaa;
    "
    />
    <div
      class="overflow-hidden flex flex-column"
    >
      <template v-if="isShow">
        <mobile-timepicker
          v-model="internalValue.startTime"
          label="시작"
        />
        <mobile-timepicker
          v-model="internalValue.endTime"
          label="종료"
        />
        <span
          class="color__333 text--color"
          style="width: 100%; display: flex;justify-content: space-between;
                 align-items: center;border-bottom: 1px solid #dddddd; height: 39px; padding: 0.325rem 0.7rem;"
        >
          <span style="font-size: 0.8rem;">{{ "거래처명 수기 작성" | translate }}</span>
          <v-switch
            v-model="internalValue.isCustomAccount"
            inset
            :false-value="'N'"
            :true-value="'Y'"
          />
        </span>
        <mobile-account
          v-if="internalValue.isCustomAccount === 'N'"
          v-model="internalValue.accountName"
          label="거래처"
          @changeAccount="changeAccount"
        />
        <mobile-input
          v-if="internalValue.isCustomAccount === 'Y'"
          v-model="internalValue.customAccountName"
          label="거래처"
        />
        <mobile-address
          v-model="internalValue.address"
          label="주소지"
          @changeAddress="changeAddress"
        />
        <mobile-select
          v-model="internalValue.workType"
          label="작업유형"
          :items="computedWorkTimeItem"
        />
        <span
          class="color__333 text--color"
          style="width: 100%; display: flex;justify-content: space-between;
                 align-items: center;border-bottom: 1px solid #dddddd; height: 39px; padding: 0.325rem 0.7rem;"
        >
          <span style="font-size: 0.8rem;">{{ "작업내용" | translate }}</span>
        </span>
        <span>
          <mps-textarea
            v-model="internalValue.workContents"
            label="작업내용"
            :rows="4"
          />
        </span>
        <div
          class="cursor center pt-4"
          @click.stop="deleteItem"
        >
          <img src="/img/svg/svg_delete.svg">
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import MobileAccount from "@/components/new-comp/forms/MobileAccount";
import MpsTextarea from "@/components/new-comp/forms/MpsTextarea";
export default {
  name: "WorkRecordItem",
  components: { MobileAccount, MpsTextarea },
  props: {
    value: {
      type: Object,
      default: undefined
    },
    workTypeOptions: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      internalValue: {
        workReportMasterId: null,
        workReportId: null,
        workDate: null,
        startTime: this.setStartTime,
        endTime: this.setEndTime,
        worker: null,
        workerName: null,
        workState: null,
        accountId: null,
        account: undefined,
        accountName: null,
        address: null,
        workType: null,
        workTypeName: null,
        workContents: null,
        accountModel: null,
        accountItems: [],
        isCustomAccount: 'N',
        customAccountName: null,
      },
      installLogShow: false,
      isShow: true,
    }
  },
  computed: {
    computedWorkTimeItem() {
      if(!this.workTypeOptions) return null
      const options = this.workTypeOptions.map((e, idx) => {
        return {label: e.codeValue, value: e.codeKey, key: `t-item-${idx}`}
      })
      options.unshift({ label: '선택안함', value: null })

      return options
    },

    detailShowTitle() {
      try {
       const result = this.internalValue.accountName

        return `${result.length > 30 ? result.substring(0, 28) + '...' : result}`
      } catch (e) {
        return ''
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if(!newValue) return
        this.internalValue = newValue
      }
    },

    internalValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      }
    },
  },

  mounted() {
    this.internalValue = this.value
  },

  methods: {
    deleteItem() {
      this.$emit('delete-item')
    },

    /**
     * MobileAddress 컴포넌트에서 호출
     * @param data
     */
    changeAddress(data) {
      if (!data) return
      this.internalValue.address = data.roadAddr || data.jibunAddr;
    },

    /**
     * MobileAccount 컴포넌트에서 호출
     * @param data
     */
    changeAccount(data) {
      if (!data) return
      this.internalValue.accountId = data.accountId
      this.internalValue.accountName = data.accountName
      if (data.address) {
        this.internalValue.address = data.address + ' ' + data.addressDetail
      }

    },
  },
}
</script>

<style scoped>
.sub_item_wrap {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
}

.sub_item_title {
  font-weight: bold;
  color: #333;
}

.button-group {
  display: flex;
  width: fit-content;
}

.show-button {
  background-image: url('/img/ic_list_arrow_down.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.9rem;
  margin: 0 0.25rem;
  cursor: pointer;
  width: 1.8rem;
  height: 1.8rem;
}

.is-show {
  transform: rotate(180deg);
}

.del-button {
  margin: 0 0.25rem;
  cursor: pointer;
  height: 1.8rem;
  line-height: 1.8rem;
}

.install_log_btn {
  padding: 9px;
  background: #ebebeb;
  font-weight: bold;
  margin: 5px;
  font-size: 0.9375rem;
}

</style>
