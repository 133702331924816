<script>
    import BaseListener from '@/assets/plugins/mps-common/event/base-listener'

    export default {
        name: "OnMainSearchTriggered",
        extends: BaseListener,
        mounted() {
            this.registerListener(this.mpsEvents.ON_MAIN_FILTER_TRIGGERED, this.onMainFilterTriggered);
        },
        unmounted() {
            this.unregisterListener(this.mpsEvents.ON_MAIN_FILTER_TRIGGERED, this.onMainFilterTriggered);
        },
        methods: {
            onMainFilterTriggered() {
                alert('onMainSearchTriggered is needed to be implemented!');
            }
        },
    }
</script>
