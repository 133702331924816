<template>
  <div class="overflow-y-auto overflow-x-hidden">
    <my-tool-bar :title="'작업일지 상세'">
      <template #buttons>
        <toolbar-save-action
          @save="onSave"
          @refresh="onRefresh"
        />
      </template>
    </my-tool-bar>
    <div style="position: relative; top: 51px; height: calc(100% - 56px);">
      <div class="date_picker_wrap flex align-center">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="searchOptions.workDate"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="searchOptions.workDate"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="searchOptions.workDate"
            type="date"
            scrollable
            color="#0C98FE"
            :locale="userManager.user.countryCode"
          >
            <v-spacer />
            <v-btn
              text
              color="#0C98FE"
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="0C98FE"
              @click="changeDate"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <div
          v-if="user.isAdmin"
          style="padding-top: 3px"
        >
          <select
            v-model="searchOptions.worker"
            class="form-select"
            @change="changeWorker"
          >
            <option
              v-for="item in staffOptions"
              :key="item.userId"
              :value="item.userId"
            >
              {{ item.userFullname }}
            </option>
          </select>
        </div>
        <div
          v-else
          style="padding-top: 3px;margin-right: 158px; margin-left: 3px;"
        >
          {{ '성명' | translate }} : {{ parentOptions.workerName }}
        </div>
      </div>
      <template v-if="!loading">
        <work-record-list
          v-model="editContext"
          :work-type-options="workTypeOptions"
          :staff-options="staffOptions"
          @delete-item="deleteItem"
        />
      </template>
      <template v-else>
        <progress-loading />
      </template>
    </div>
  </div>
</template>

<script>
/**
 * 임대계약 및 프린터 계약 화면입니다.
 */

import {PATH} from "@/constants";
import {cloneDeep} from "lodash";
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import MyToolBar from "@/components/app/toolbar/MyToolBar";
import ToolbarSaveAction from "@/components/app/toolbar/ToolbarSaveAction";
import ProgressLoading from "@/components/part/ProgressLoading";
import VuexProps from "@/framework/vuex/VuexProps";
import workRecordList from "@/views/home/WorkRecordList";
import AccountAutocomplete from "@/views/home/js/account-autocomplete";
import {DateTime} from "luxon";

export default {
  name: "WorkRecordDetail",
  components: { ProgressLoading, ToolbarSaveAction, MyToolBar, workRecordList},
  extends: WindowComponent,
  mixins: [VuexProps],
  props: { parentOptions: undefined,},
  data() {
    return {
      accountInfo: undefined,
      context: {
        contractId: undefined,
        contractNo: undefined,
        contractDate: undefined,
        contractType: undefined,
        contractStartdate: undefined,
        contractEnddate: undefined,
        payType: null,
        realYn: undefined,
        contractAmount: undefined,
        memo: undefined,
        contractFee: undefined,
        filename: undefined,
        orgFilename: undefined,
        contractCancelDate: undefined,
        contractCancel: undefined,
        contractState: undefined,
        serialVersionUID: undefined,
        billDay: undefined,
        calculationStartdate: undefined,
        taxSchDay: undefined,
        statementIssueDay: undefined,
        cmsMemberId: undefined,
        tmId: undefined,
        tsmId: undefined,
      },
      editContext: undefined,
      deleteContext: undefined,
      loading: false,
      searchOptions: {
        worker: null,
        start: null,
        end: null,
        workDate: this.$now().endOf('day').toISODate(),
        workerName:null,
        sortBy: [],
        sortDesc: [],
      },
      modal: false,
      workTypeOptions: undefined,
      staffOptions: undefined,

    }
  },
  watch: {
    context: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.editContext = cloneDeep(newValue)
      }
    },
    
  },
  mounted() {
   this.workTypeListLoad()
   this.loadOptionsEmployee()
   this.setSearchOptions()
   this.loadData()
  },
  unmounted() {
    this.context = undefined
    this.editContext = undefined
  },


  methods: {
    changeWorker() {
      this.loadData()
    },
    /**
     * 작업상태조회
     */
    workTypeListLoad() {
      this.$request(PATH.OPTIONS_LOAD_CODE_RENTAL_COMPANY_CODES)
          .setObject({ codeType: 'WORK_TYPE' })
          .enqueue()
          .then((res) => {
            this.workTypeOptions = res.data.filter((e) => e.showYn === 'Y')
          })
    },

    /**
     * 작업자 조회
     */
    loadOptionsEmployee() {
      this.$request(PATH.STAFF_EMPLOYEES_LIST)
          .post()
          .enqueue()
          .then((res) => {
            this.staffOptions = res.data
          })
    },

    /**
     * 날짜 필터 변경
     */
    changeDate(){
      this.$refs.dialog.save(this.searchOptions.workDate)
      this.loadData()
    },

    /**
     * 부모에게서 넘어온 옵션 세팅
     */
    setSearchOptions() {
      this.searchOptions.worker = this.parentOptions.worker || this.user.userId
      this.searchOptions.workDate = this.parentOptions.workDate || this.$now().endOf('day').toISO()
    },


    /**
     * 기초 데이터 로드
     */
    async loadData() {
      this.editContext = []
      this.context = []
      this.deleteContext = []
      const params = {
        start: this.searchOptions.workDate,
        end: this.searchOptions.workDate,
        worker: this.searchOptions.worker,
        searchKeyword: this.searchOptions.searchKeyword,
        sortBy: ['endTime'],
        sortDesc: [false],
      }
      const res = await this.$request(PATH.REPORT.WORK_REPORT.LIST)
          .setObject(params)
          .enqueue()
      this.context = res.data

      // 필요한 컬럼 값으로 변경하여준다.
      this.context.map((e)=>{
        e.workReportMasterId = e.masterId
            e.workReportId= e.reportId
            e.workDate= e.workDate
            e.startTime= e.startTime
            e.endTime= e.endTime
            e.worker= e.worker
            e.workerName= e.workerName
            e.workState= e.workState
            e.accountId= e.accountId
            e.accountName= e.accountName
            e.customAccountName= e.accountName
            e.address= e.address
            e.workType= e.workType
            e.workTypeName= e.workTypeName
            e.workContents= e.workContents
            e.accountModel= null
            e.accountItems= []
            e.isCustomAccount= e.accountId > 0 ? 'N' : 'Y'
      })
      this.context.forEach((e) => {
        if (e.accountId > 0) {
          e.accountModel = new AccountAutocomplete(e)
          e.accountItems = [e.accountModel]
        }
      })
    },

    /**
     * 리셋 버튼 클릭
     * @returns {Promise<void>}
     */
    async onRefresh() {
      this.$dialog()
        .message(this.$translate('편집중인 정보를 초기화합니다.'))
        .buttonNegative(this.$translate('취소'))
        .buttonPositive(this.$translate('초기화'), () => {
          this.editContext = cloneDeep(this.context)
        })
        .build().show();
    },

    /**
     * 저장 버튼 클릭
     * @returns {Promise<*>}
     */
    async onSave() {
      this.$dialog()
        .message(this.$translate('저장하시겠습니까?'))
        .buttonNegative(this.$translate('임시저장'), () => {
          this.onTempSaveClicked()
        }).buttonPositive(this.$translate('저장'), () => {
        this.onSaveClicked()
      }).build().show();
    },

    /**
     * 임시저장
     */
    onTempSaveClicked() {
      this.requestSave(true)
    },

    /**
     * 저장
     */
    onSaveClicked() {
      this.requestSave(false)
    },

    async requestSave(temp, workDate) {
      if (this.loading) return
      this.loading = true
      // 삭제된 내역이나 수정사항이 없을때.
      if (this.editContext.length < 1 ) {
        this.$alert('변경사항이 없습니다.')
        this.loading = false
      }
      this.editContext.forEach((e) => {
        e.workState = temp ? 'R' : 'Y'
        e.accountId = e.isCustomAccount === 'Y' ? -1 : e.accountId
        e.accountName =
            e.isCustomAccount === 'Y' ? e.customAccountName : e.accountName
        e.workDate = !workDate ? this.searchOptions.workDate : workDate
        e.worker = this.searchOptions.worker
      })
      const arr = []
      if (this.context.length < 1) {
        this.editContext.forEach((edit) => arr.push(edit))
      } else {
        this.editContext.forEach((edit) => {
          if (this.dirtyCheck(edit)) {
            edit.accountModel = null
            edit.accountItems = []
            arr.push(edit)
          }
        })

        // 삭제된 아이템은 삭제버튼 아이콘 클릭 시 editContext에서 삭제되고 deleteItem에 deleteYn === 'Y' 로 변경되어 담아진다.
        this.deleteContext.forEach((deleteItem) => {
          deleteItem.accountModel = null
          deleteItem.accountItems = []
          arr.push(deleteItem)
        })
      }
      if (arr.length > 0) {
        try {
          const res = await this.$request(PATH.REPORT.WORK_REPORT.UPDATE)
              .setObject(arr)
              .enqueue()
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
          this.loadData()
          this.$snackbar(this.$translate("등록되었습니다."));
        }
      } else {
        this.loading = false
        this.$snackbar(this.$translate("변경사항이 없습니다."));
      }
    },

    // 변경 및 추가 항목 : return true;
    dirtyCheck(editItem) {
      // 신규 항목으로 판단.
      if (
          !editItem.workReportId &&
          (!!editItem.accountName ||
              !!editItem.address ||
              !!editItem.workType ||
              !!editItem.workContents)
      ) {
        return true
      } else if (!editItem.workReportId) {
        return false
      }

      const idx = this.context.findIndex(
          (e) => e.reportId === editItem.workReportId
      )

      return (
          editItem.startTime !== this.context[idx].startTime ||
          editItem.endTime !== this.context[idx].endTime ||
          editItem.accountId !== this.context[idx].accountId ||
          editItem.accountName !== this.context[idx].accountName ||
          editItem.address !== this.context[idx].address ||
          editItem.workType !== this.context[idx].workType ||
          editItem.workContents !== this.context[idx].workContents ||
          editItem.workState !== this.context[idx].workState
      )
    },

    /**
     * 자식창에서 호출되며 workReportId가 있는 경우에만 push.
     * @param item
     */
    deleteItem(item) {
      this.deleteContext.push(item)
    },

    /**
     * 벨리데이션 체크
     * @returns {*}
     */
    checkValidate() {
      const checked = this.editSubContext.some(e => {
        return (e.modelType === 'ITEM' && (!e.companyModelId || e.companyModelId == null || e.companyModelId === 0 || e.companyModelId === '0'))
          || (e.modelType !== 'ITEM' && e.printerModelName == null)
      })

      return checked
    },



  },
}
</script>

<style scoped>
.date_picker_wrap .v-input {
  margin: 0;
  padding-left: 10px;
  width: 110px;
}

.date_picker_wrap >>> .v-text-field__details {
  display: none;
}

.date_picker_wrap >>> .v-text-field input {
  padding: 9px;
  background-image: url(/img/dashboard_arrow.svg);
  background-repeat: no-repeat;
  background-position: 92% center;
  background-size: 8px;
  font-size: 0.917rem;
}

.date_picker_wrap >>> .v-text-field__slot {
  background-color: white;
  border: 1px solid #ccc;
  width: 50px;
  height: 34px;
  border-radius: 4px;
  margin-right: 2.8%;
}

.date_picker_wrap >>> .v-input__slot:before {
  display: none;
}

.date_picker_wrap >>> .v-input__slot:after {
  display: none;
}

select.form-select {
  text-align-last:center;
  background-color: white;
  border: 1px solid #ccc;
  width: 100px;
  height: 34px;
  border-radius: 4px;
  margin-right: 180px;
}

select.form-select > option {
  text-align: right;
  text-align-last: right;
  font-size: 1rem;
}

</style>
